"use client";

import { usePathname } from "next/navigation";
import { useEffect } from "react";

export async function handleError(pathname: string | null) {
  await waitTrackJsAndReport({ pathname });
}

export async function waitTrackJsAndReport({
  pathname,
  error,
}: {
  pathname?: string | null;
  error?: Error;
}) {
  //allow error tracking to initialise before reporting

  return new Promise((resolve) => {
    let attempts = 0;

    const reportError = () => {
      if (!window.TrackJS) {
        if (attempts > 5) {
          resolve(null);
          return;
        }

        attempts++;
        setTimeout(reportError, 500);
      }
      if ((window as any)?.TrackJS?.isInstalled()) {
        pathname
          ? window.TrackJS?.track(new Error("404 error occurred at: " + pathname))
          : error
            ? window.TrackJS?.track(error)
            : "";
      }
      resolve(null);
      return;
    };

    setTimeout(reportError, 0);
  });
}

export const ClientErrorInfo = () => {
  const pathname = usePathname();
  useEffect(() => {
    handleError(pathname);
  }, [pathname]);
  return <div className="hidden" />;
};
