"use client";
import React from "react";

import { IllustrationGlobe } from "@plum/plum-ui-next";

import { Text, Link } from "@plum/ui-core";

const MessageLink = ({ href, label }) => {
  return (
    <Link _underline className="self-start" href={href}>
      <Text as="span">{label}</Text>
    </Link>
  );
};

type ErrorMessageProps = {
  title: string;
  copy: string;
};

export const ErrorMessage = ({ title, copy }: ErrorMessageProps) => {
  return (
    <div className="pt-[16vh]">
      <div className="grid px-4">
        <div className="w-[70px] h-[70px] sm:w-[100px] sm:h-[70px] justify-self-center">
          <IllustrationGlobe scribble />
        </div>
        <div className="w-full h-[48px]"></div>
        <div className="sm:justify-self-center sm:self-center px-4">
          <Text
            color="neutrals-9"
            className="text-[28px] sm:text-h-sm text-left sm:text-center max-w-[90%] sm:max-w-[600px]"
            as={"h1"}
            _serif
            _bold
          >
            {title}
          </Text>
          <div className="w-full h-[48px]"></div>
          <Text className="text-left sm:text-center text-b-md sm:text-b-lg">{copy}</Text>
          <div className="h-[16px] sm:h-[32px]"></div>
          <div className="flex flex-col sm:flex-row gap-y-2 gap-x-8 sm:justify-center">
            <MessageLink label={"Homepage"} href="/" />
            <MessageLink label={"Search our homes"} href="/search" />
            <MessageLink label={"Help center"} href="https://help.plumguide.com/en/" />
          </div>
        </div>
      </div>
    </div>
  );
};
